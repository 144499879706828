import React, { useEffect, useState } from 'react'
import { Button, useShowError, useShowSuccessMessage } from '@aserto/console-common'

import { useResendVerificationEmail, useSyncUser } from '../../api/account'
import { useAccount } from '../../api/account'
import celebration from '../../assets/celebration.svg'
import gradient from '../../assets/gradient.svg'
import signup from '../../assets/signup.svg'
import { useHubSpotClient } from '../../clients/HubSpotClient'
import {
  ButtonsContainerSignUp,
  CelebrationImage,
  Container,
  Image,
  PaddedContainer,
  RowPlacement,
  SignupContainer,
  TextArea,
  TextAreaBold,
  TextContainer,
  Title,
} from './styles'

const VerifyEmail: React.FC = () => {
  const { data: accountData } = useAccount()
  const showSuccess = useShowSuccessMessage()
  const showError = useShowError()
  const { hubspotCreateContact } = useHubSpotClient()
  const [continueButton, setContinueButton] = useState(false)
  const email = String(accountData?.result?.email)

  const { mutateAsync: resendVerification, isLoading: isResendLoading } =
    useResendVerificationEmail({
      onSuccess: () => {
        showSuccess('A verification email has been sent')
      },
      onError: (err) => {
        showError(err)
      },
    })
  const { mutate: syncUser } = useSyncUser({
    onError: (err) => {
      showError(err)
    },
    onSettled: () => {
      setContinueButton(false)
    },
  })
  const [createdContact, setCreatedContact] = useState(false)

  useEffect(() => {
    if (!createdContact) {
      setCreatedContact(true)
      try {
        hubspotCreateContact({
          email,
        })
      } catch {}
    }
  }, [createdContact, email, hubspotCreateContact])

  return (
    <>
      <PaddedContainer>
        <SignupContainer>
          <Container>
            <img src={signup} />
            <RowPlacement>
              <CelebrationImage src={celebration}></CelebrationImage>
              <Title>You're almost there!</Title>
            </RowPlacement>
          </Container>
          <Image src={gradient} />
          <br />
          <TextContainer>
            <TextArea>
              An email is on its way to {email} from "hello@aserto.com". If you don't see it, be
              sure to check your spam folder.
            </TextArea>
            <TextArea>
              Once you receive it, click the confirmation link to verify your email address and
              continue with the onboarding. If you don't see the email, you can resend it below.
            </TextArea>
            <br />
            <TextAreaBold>The Aserto Team</TextAreaBold>
            <ButtonsContainerSignUp>
              <Button
                disabled={isResendLoading}
                variant="primary-outline"
                onClick={() => {
                  resendVerification({})
                }}
              >
                Resend email
              </Button>
              <Button
                data-testid="delete-subject"
                disabled={continueButton}
                variant="primary-outline"
                onClick={() => {
                  setContinueButton(true)
                  syncUser({})
                }}
              >
                Continue
              </Button>
            </ButtonsContainerSignUp>
          </TextContainer>
        </SignupContainer>
      </PaddedContainer>
    </>
  )
}
export default VerifyEmail
