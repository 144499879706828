import { useMemo } from 'react'
import { MutationOptions, QueryKey, UseInfiniteQueryResult, useQueryClient } from 'react-query'
import { useShowError } from '@aserto/console-common'

import { RpcStatus } from './tenant'

export enum MutationKeys {
  ClaimTenant = 'claimTenant',
  CreateConnection = 'CreateConnection',
  CreatePolicy = 'CreatePolicy',
  CreatePolicyBuilder = 'CreatePolicyBuilder',
  CreatePolicyState = 'CreatePolicyState',
  DeleteConnection = 'DeleteConnection',
  DeletePolicyBuilder = 'DeletePolicyBuilder',
  DownloadTodoApp = 'DownloadTodoApp',
  ExecCommand = 'ExecCommand',
  RemoveTenantMember = 'RemoveTenantMember',
  RotateKey = 'RotateKey',
  RunWorkflow = 'RunWorkflow',
  SetPolicyState = 'SetPolicyState',
  UpdateAccount = 'UpdateAccount',
  UpdateConnection = 'UpdateConnection',
  UpdateRole = 'UpdateRole',
  UpdateTenantObject = 'UpdateAccountTenant',
}

export enum QueryKeys {
  Account = 'Account',
  AccountInvitesData = 'accountInvitesData',
  AonaIdentity = 'AonaIdentity',
  AuthorizerInfo = 'AuthorizerInfo',
  AuthzDataDecisiontree = 'authzDataDecisiontree',
  AuthzDataIs = 'authzDataIs',
  AuthzDataQuery = 'authzDataQuery',
  Config = 'Config',
  ConnectionsData = 'connectionsData',
  CreateAccountNameValidation = 'createAccountNameValidation',
  DecisionLogsQuery = 'decisionLogsQuery',
  ExecCommandData = 'execCommandData',
  GetPolicyState = 'getPolicyState',
  GetSccProfile = 'sccProfileData',
  GetTenantProfile = 'tenantProfileData',
  Info = 'Info',
  InvitesData = 'invitesData',
  IsConnectionNameAvailable = 'IsConnectionNameAvailable',
  IsPolicyNameAvailable = 'isPolicyNameAvailable',
  IsPolicyRepoConnected = 'IsPolicyRepoConnected',
  IsPolicyRepoTagValid = 'IsPolicyRepoTagValid',
  IsRegistryRepoAvailable = 'IsRegistryRepoAvailable',
  ListInstanceRegistrations = 'listInstanceRegistrations',
  ListOnboardingTemplates = 'listOnboardingTemplates',
  ListPolicy = 'listPolicy',
  ListPolicyModules = 'ListPolicyModules',
  ListPolicyRepos = 'policyReposData',
  ListPolicyRepoTags = 'policyRepoTagsData',
  ListRegistryOrgs = 'registryOrganizationsData',
  ListRegistryRepoDigests = 'registryRepoDigestsData',
  ListSccOrganizations = 'sccOrganizationsData',
  ListSccRepo = 'sccRepositoriesData',
  ListSccTemplates = 'sccTemplatesData',
  ListTemplates = 'listTemplates',
  ListTenantMembers = 'ListTenantMembers',
  Manifest = '/api/v3/directory/manifest',
  ManifestReference = 'manifestReference',
  PoliciesData = 'policiesData',
  PolicyBuilderData = 'PolicyBuilderData',
  PolicyData = 'policyData',
  PolicyModuleData = 'policyModuleData',
  ProvidersData = 'providersData',
  RolesData = 'rolesData',
  RunWorkflowData = 'runWorkflowData',
  SetPolicyState = 'setPolicyState',
  SystemStatusData = 'systemStatusData',
  SystemTenantsData = 'systemTenantsData',
  TenantObject = 'tenantAccount',
  User = 'User',
  UserData = 'userData',
  UsersData = 'usersData',
}

// default cache interval is 5 minutes
const REACT_QUERY_STALE_TIME = 5 * 60 * 1000
// connection details contain secrets and are only cached for 20 seconds
const REACT_QUERY_CONNECTION_STALE_TIME = 20 * 1000

// This allows us to specify just the TError for React Query without specifying the full
// four input types. Infrence can continue to handle the rest!
interface HasRpcStatusErrorHandler {
  onError: (error: RpcStatus) => void
  [x: string]: unknown
}

export const reactQueryDefaultOptions: HasRpcStatusErrorHandler = {
  staleTime: REACT_QUERY_STALE_TIME,
  cacheTime: REACT_QUERY_STALE_TIME,
  onError: () => {},
}

export const reactQueryConnectionCacheOptions: HasRpcStatusErrorHandler = {
  staleTime: REACT_QUERY_CONNECTION_STALE_TIME,
  cacheTime: REACT_QUERY_CONNECTION_STALE_TIME,
  onError: () => {},
}

export const useMutationOptions = <TData = unknown, TVariables = void>(
  mutationHooks: ReactQueryMutationHooks<TData, TVariables>,
  ...queryKeys: (((d: TData, variables: TVariables, context: void) => QueryKey) | QueryKey)[]
): MutationOptions<TData, RpcStatus, TVariables, void> => {
  const queryClient = useQueryClient()
  const showError = useShowError()

  const getQueryKeys = queryKeys.map((queryKey) => {
    if (typeof queryKey === 'function') {
      return queryKey
    } else {
      return () => queryKey
    }
  })

  return {
    ...mutationHooks,
    onError: (error, variables, context) => {
      showError(error)
      mutationHooks.onError?.(error, variables, context)
    },
    onSuccess: (data, variables, context) => {
      getQueryKeys.map((getQueryKey) => {
        queryClient.invalidateQueries(getQueryKey(data, variables, context))
      })
      mutationHooks?.onSuccess?.(data, variables, context)
    },
  }
}

export interface ReactQueryMutationHooks<TData = unknown, TVariables = void> {
  onMutate?: (variables: TVariables) => Promise<void> | void
  onSuccess?: (data: TData, variables: TVariables, context: unknown) => Promise<void> | void
  onError?: (error: RpcStatus, variables: TVariables, context: void) => Promise<void> | void
  onSettled?: (
    data: TData | undefined,
    error: RpcStatus | null,
    variables: TVariables,
    context: void
  ) => Promise<void> | void
}

export type UseInfiniteQueryResultDecorated<TData, TError, TElement> = UseInfiniteQueryResult<
  TData,
  TError
> & { allData: TElement[] | undefined }

export const useInfiniteQueryResultDecorations = <TData, TError, TElement>(
  result: UseInfiniteQueryResult<TData, TError>,
  reducer: (accumulator: TElement[], current: TData) => TElement[]
): UseInfiniteQueryResultDecorated<TData, TError, TElement> => {
  const allData = useMemo(
    () => result.data?.pages.reduce(reducer, []),
    [reducer, result.data?.pages]
  )

  return {
    ...result,
    allData: allData,
  }
}
