import React, { useMemo } from 'react'
import { SuccessBannerProvider, useIdentity } from '@aserto/console-common'

import { useAccount } from '../../api/account'
import VerifyEmail from '../../views/VerifyEmail'

const VerifyEmailProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: accountData, isLoading } = useAccount()
  const { logout } = useIdentity()
  if (window.location.pathname === '/logout') {
    logout()
  }

  const enabledAccount = useMemo(() => {
    return accountData?.result?.enabled
  }, [accountData?.result])

  if (isLoading) {
    return null
  }

  if (!enabledAccount) {
    return (
      <SuccessBannerProvider>
        <VerifyEmail></VerifyEmail>
      </SuccessBannerProvider>
    )
  }
  return <>{children}</>
}
export default VerifyEmailProvider
