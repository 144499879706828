import React, { PropsWithChildren } from 'react'

type CachePreloaderProps = {
  block?: boolean
}

const CachePreloader: React.FC<PropsWithChildren<CachePreloaderProps>> = ({
  block = false,
  children,
}) => {
  const loaded: boolean[] = []
  // push anything that should be loaded before rendering
  const allLoaded = !loaded.some((i) => !i)

  return block && !allLoaded ? null : <>{children}</>
}

export default CachePreloader
