import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query'
import { InternalProfileProvider, useShowError, useStorage } from '@aserto/console-common'

import { useAccount } from '../../api/account'
import { Account } from '../../types/local/consoleBackend'
import { QueryKeys, reactQueryDefaultOptions } from '../../types/local/general'
import { Tenant } from '../../types/local/tenant'
import { useAserto } from '../../utils/backendapi'

export type ProfileContextProps = {
  account: Account | null
  invalidateAccountCache: () => void
  tenant: Tenant | null
  setTenantId: (id: string, navigateToUrl?: string) => void
}

const ProfileContext = React.createContext<ProfileContextProps>({
  account: null,
  invalidateAccountCache: () => {},
  tenant: null,
  setTenantId: () => {},
})

export const useProfile = () => {
  return useContext(ProfileContext)
}

const ProfileAndQueryClientProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const showError = useShowError()
  const [queryClient] = useState<QueryClient>(
    new QueryClient({
      defaultOptions: {
        queries: {
          ...reactQueryDefaultOptions,
          onError: undefined,
        },
      },
    })
  )
  const outerQueryClient = useQueryClient()
  const { init: initAserto } = useAserto()
  const { data: accountData, error: accountError } = useAccount()

  const account = useMemo(() => accountData?.result, [accountData?.result])

  const [tenantId, setTenantId] = useStorage<string | undefined>('tenantId', undefined)

  useEffect(() => {
    if (!!account && !!tenantId) {
      if (!getTenantById(account.tenants || [], tenantId)) {
        setTenantId(undefined)
      }
    }
  }, [account, setTenantId, tenantId])

  useEffect(() => {
    if (!!account && !tenantId) {
      setTenantId(selectTenant(account)?.id)
    }
  }, [account, setTenantId, tenantId])

  const tenant = useMemo(() => {
    if (!!account && !!tenantId) {
      return getTenantById(account.tenants || [], tenantId)
    }
  }, [account, tenantId])

  const hasError = !!accountError
  const isLoading = !accountData

  useEffect(() => {
    if (hasError) {
      showError(accountError)
    }
  }, [accountError, hasError, showError])

  useEffect(() => {
    if (!!tenant?.id) {
      initAserto(tenant.id).catch((error) => showError(error))
    }
  }, [initAserto, showError, tenant?.id])

  const setTenantIdExternal = useCallback(
    (id: string, navigateToUrl?: string) => {
      outerQueryClient.clear()
      queryClient.clear()
      setTenantId(id)
      window.location.href = navigateToUrl ?? '/'
    },
    [outerQueryClient, queryClient, setTenantId]
  )

  const invalidateAccountCache = useCallback(() => {
    outerQueryClient.invalidateQueries(QueryKeys.Account)
  }, [outerQueryClient])

  const value: ProfileContextProps = useMemo(
    () => ({
      account: account || null,
      invalidateAccountCache,
      tenant: tenant || null,
      setTenantId: setTenantIdExternal,
    }),
    [account, invalidateAccountCache, setTenantIdExternal, tenant]
  )

  if (hasError || isLoading) {
    return null
  }

  return (
    <ProfileContext.Provider value={value}>
      <QueryClientProvider client={queryClient}>
        <InternalProfileProvider
          profile={{ account: account || null, tenantId: tenant?.id, setTenantId }}
        >
          {children}
        </InternalProfileProvider>
      </QueryClientProvider>
    </ProfileContext.Provider>
  )
}

export const MemoryProfileAndQueryClientProvider: React.FC<
  PropsWithChildren<ProfileContextProps>
> = ({ children, ...value }) => {
  return (
    <ProfileContext.Provider value={value}>
      <QueryClientProvider client={new QueryClient()}>{children}</QueryClientProvider>
    </ProfileContext.Provider>
  )
}

const selectTenant = (account: Account): Tenant | undefined => {
  if (account.default_tenant_id) {
    const defaultTenant = getTenantById(account.tenants || [], account.default_tenant_id)

    if (defaultTenant) {
      return defaultTenant
    }
  }

  return account.tenants?.[0]
}

const getTenantById = (tenants: Array<Tenant>, id: string): Tenant => {
  return tenants.find((tenant: Tenant) => tenant.id === id) as Tenant
}

export default ProfileAndQueryClientProvider
