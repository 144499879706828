type Config = {
  directoryServiceUrl: string
  authorizerServiceUrl: string
  backendServiceUrl: string
  decisionLogsServiceUrl: string
  fullStoryOrgId: string
  funnelServiceUrl: string
  isProduction: boolean
  oauthServiceUrl: string
  readmeIoKey: string
  relayServiceUrl: string
  sentryDsn: string
  tenantServiceUrl: string
}

export const emptyConfig: Config = {
  authorizerServiceUrl: '',
  backendServiceUrl: '',
  decisionLogsServiceUrl: '',
  fullStoryOrgId: '',
  funnelServiceUrl: '',
  isProduction: true,
  oauthServiceUrl: '',
  readmeIoKey: '',
  sentryDsn: '',
  tenantServiceUrl: '',
  directoryServiceUrl: '',
  relayServiceUrl: '',
} as const

export default Config
